import React, {ReactElement, RefObject, useRef} from 'react';
import {Container, Row, Col, Stack, Card} from 'react-bootstrap';
import {links} from "../../links";

export type SplashViewProps = {
    fifthViewRef : RefObject<HTMLDivElement> | null
}
export default function SplashView({fifthViewRef} : SplashViewProps) {

    const secondViewRef = useRef<HTMLDivElement>(null)
    const executeScrollToSecondView = () => secondViewRef?.current?.scrollIntoView()

    return (
        <>
            <FirstView handleLearnMoreClick={executeScrollToSecondView}/>
            <div ref={secondViewRef}>
            <SecondView/>
            </div>
            <ThirdView/>
            <FourthView/>
            <div ref={fifthViewRef}>
                <FifthView/>
            </div>
        </>
    )
}


type FeatureViewProps = {
    title : string
    body : ReactElement
    imgURL : string
    exampleLinkText : string | null
    exampleLinkURL : string | null
    imgHeight : number
}
function FeatureView({title, body, exampleLinkText, exampleLinkURL, imgURL, imgHeight}:FeatureViewProps) {

    let link : ReactElement
    if (exampleLinkText !== null && exampleLinkURL !== null) {
        link =
            <p className="pt-2">
                <a href={exampleLinkURL}>{exampleLinkText}</a>
            </p>
    } else {
        link = <></>
    }

    return (
        <>
            <Row className="ps-4">
                <Col xs={4} className="my-auto">
                    <img
                        alt=""
                        src={imgURL}
                        style={{height:imgHeight}}
                    />
                </Col>
                <Col xs={8} className="text-start">
                    <strong>{title}</strong> — {body}
                </Col>
            </Row>
            <Row className="ps-4">
                <Col xs={4} className="my-auto">
                </Col>
                <Col xs={8} className="text-start">
                    {link}
                </Col>
            </Row>
        </>
    )
}

type PurchaseCardProps = {
    title: string
    body: ReactElement
}
function PurchaseCard({title, body}: PurchaseCardProps) {
    return (
        <>
            <Card style={{width:"28rem"}}>
                <Card.Body className="border border-1 border-dark">
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>
                        {body}
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

function FifthView(){
    return (
        <Container fluid className="p-5">
            <h1 className="pb-4">Plans & Pricing</h1>
            <Row xs={1} md={2} className="g-4">
                <Col className="d-flex justify-content-center">
                    <PurchaseCard
                        title="Monthly Subscription"
                        body= {
                            <>
                                <p className="text-muted">{'\u00A0'}</p>
                                <p>Web, iOS and Android access.</p>
                                <p>$11.49 USD per month</p>
                            </>
                        }
                    />
                </Col>
                <Col className="d-flex justify-content-center">
                    <PurchaseCard
                        title="Annual Subscription"
                        body= {
                            <>
                                <p className="text-muted">includes 7-day free trial</p>
                                <p>Web, iOS and Android access.</p>
                                <p>$119.99 USD per year</p>
                            </>
                        }
                    />
                </Col>
                <Col className="d-flex justify-content-center">
                    <PurchaseCard
                        title="Institutional Subscription"
                        body= {
                            <>
                                <p className="text-muted"></p>
                                <p>Unlock access for your institutions learners, educators and clinicians.</p>
                                <a href="mailto:contact@doclogica.com">Contact Us</a>
                            </>
                        }
                    />
                </Col>
                <Col className="d-flex justify-content-center">
                    <PurchaseCard
                        title="API Access"
                        body= {
                            <>
                                <p className="text-muted"></p>
                                <p>Access the most powerful diagnostic database in the world with our <a target="_blank" href={links.apiexample}>custom API</a>.</p>
                                <a href="mailto:contact@doclogica.com">Contact Us</a>
                            </>
                        }
                    />
                </Col>
            </Row>
        </Container>
    )
}

type QuotationProps = {
    body: ReactElement
    author: string
    leading: boolean
}
function Quotation({body, author, leading}: QuotationProps){
    let bodyElement : ReactElement
    let quotationHeight: number
    if (leading) {
        bodyElement = <h2 className={"text-secondary"}>{body}</h2>
        quotationHeight = 40
    } else {
        bodyElement = <h4 className={"text-secondary"}>{body}</h4>
        quotationHeight = 30
    }
    return (
        <>
            <img
                alt=""
                src="/quotation_open.svg"
                style={{height:quotationHeight}}
                className=""
            />
            {bodyElement}
            <h5 className={"text-secondary"}>— {author}</h5>
            <img
                alt=""
                src="/quotation_close.svg"
                style={{height:quotationHeight}}
                className=""
            />
        </>
    )
}

function FourthView() {
    return (
        <Container fluid className="pt-5 pb-5 bg-light">
            <h1 className="mb-4">See what our users have to say...</h1>
            <Quotation body=
                           {<>docLogica is a <strong>peek into the future...</strong></>}
                       author=
                           {"imedicalapps.com"}
                       leading = {true}
            />
            <Row className="pt-3 ps-5 pe-5 pb-5">
                <Col className="s">
                    <Quotation body=
                                   {<><strong>Amazing</strong>. This app has helped me so much in my medical practice. I use it every day.</>}
                               author=
                                   {"briggs55, Google Play Store"}
                               leading = {false}
                    />
                </Col>
                <Col>
                    <Quotation body=
                                   {<><strong>Great source of information</strong> in a user-friendly format. I especially love the test characteristics which include references. And the pre and post-test probability calculator is a fantastic teaching tool.</>}
                               author=
                                   {"med826, Apple App Store"}
                               leading = {false}
                    />
                </Col>
            </Row>
        </Container>
    )
}

function ThirdView() {
    const imgHeight = 50
    return (
        <Container fluid className="pt-5 pb-5">
            <Row>
                <h1 className="pb-5">Why choose docLogica?</h1>
                <Col>
                    <Stack gap={3}>
                        <FeatureView
                            title = {"Made for Doctors, by Doctors"}
                            body={<>
                                The docLogica database and app were created by a combined physician / software engineer.
                            </>}
                            imgURL={"./physician.svg"}
                            exampleLinkText={null}
                            exampleLinkURL={null}
                            imgHeight={imgHeight}
                        />
                        <FeatureView
                            title = {"Seven Years in the Making"}
                            body={<>
                                Data for the app was sourced manually from the primary medical literature with over 3,000 unique references.
                            </>}
                            imgURL={"./magnify_data.svg"}
                            exampleLinkText={null}
                            exampleLinkURL={null}
                            imgHeight={imgHeight}
                        />
                        <FeatureView
                            title = {"More Robust Data than Competitors"}
                            body={<>
                                Our database is the largest of its kind and always growing.
                            </>}
                            imgURL={"./data.svg"}
                            exampleLinkText={null}
                            exampleLinkURL={null}
                            imgHeight={imgHeight}
                        />
                        <FeatureView
                            title = {"Population Data"}
                            body={<>
                                No other DDx Generator utilizes incidence data, grouped by age and sex when possible. You can also view this data for individual diseases at a touch of a button.
                            </>}
                            imgURL={"./population.svg"}
                            exampleLinkText={null}
                            exampleLinkURL={null}
                            imgHeight={imgHeight}
                        />
                        <FeatureView
                            title = {"Want More Information?"}
                            body={<>
                                Check out <a href="/blog">our blog</a>.
                            </>}
                            imgURL={"./blog.svg"}
                            exampleLinkText={null}
                            exampleLinkURL={null}
                            imgHeight={imgHeight}
                        />
                    </Stack>
                </Col>
                <Col className="my-auto">
                    <img
                        alt=""
                        src="./screen2.png"
                        style={{height:"80vh", filter: "drop-shadow(15px 10px 8px #666666)"}}
                        className=""
                    />
                </Col>
            </Row>
        </Container>
    )
}

function SecondView() {
    const imgHeight = 80
    return (
        <Container fluid className="bg-light pt-5 pb-5">
            <Row>
                <h1 className="pb-5">Features</h1>
                <Col>
                    <Stack gap={3}>
                        <FeatureView
                            title = {"Disease Symptoms"}
                            body={<>
                                View the characteristics of over 1,700 diseases (symptoms, population incidences and more). Forgot what the symptoms of hypomagnesemia are? Find out with just a few taps.
                            </>}
                            imgURL={"/virus.svg"}
                            exampleLinkText={null}
                            exampleLinkURL={null}
                            imgHeight={imgHeight}
                        />
                        <FeatureView
                            title = {"Test Accuracies"}
                            body={<>
                                View over 1,200 diagnostic test accuracies. Easily see how testing changes probability, so you cut down on ordering useless tests.
                            </>}
                            imgURL={"./test.svg"}
                            exampleLinkText={null}
                            exampleLinkURL={null}
                            imgHeight={imgHeight}
                        />
                        <FeatureView
                            title = {"DDx Generator"}
                            body={<>
                                Create differential diagnoses from a list of hundreds of symptoms on the fly and rank it by disease incidence from actual population data.
                            </>}
                            imgURL={"./ddx.svg"}
                            exampleLinkText={null}
                            exampleLinkURL={null}
                            imgHeight={imgHeight}
                        />
                        <FeatureView
                            title = {"Disease Probabilities"}
                            body={<>
                                View ranked probabilities for the etiologies behind hundreds of common complaints and syndromes.
                            </>}
                            imgURL={"./pie.svg"}
                            exampleLinkText={null}
                            exampleLinkURL={null}
                            imgHeight={imgHeight}
                        />
                    </Stack>
                </Col>
                <Col className="my-auto">
                    <img
                        alt=""
                        src="./screen1.png"
                        style={{height:"80vh", filter: "drop-shadow(15px 10px 8px #666666)"}}
                        className=""
                    />
                </Col>
            </Row>
        </Container>
    )
}

type FirstViewProps = {
    handleLearnMoreClick: () => void
}
function FirstView({handleLearnMoreClick} : FirstViewProps) {
    return (
        <Container fluid>
            <Row className="">
                <Col className="ps-5 my-auto pe-5">
                    <h1 className="text-start display-4 pt-5">The Ultimate <strong className="text-primary">Evidence Based</strong> Diagnostic Database</h1>
                    <p className="text-start fs-5 fw-light pt-4">No one can remember all the symptoms, nor all the tests, of the thousands of diseases that exist. <span className="fw-bold text-primary">docLogica</span> is a comprehensive, searchable database of disease characteristics and test accuracies that lets you keep all that information on hand, at the bedside. Want to <a href="javascript:void(0);" onClick={handleLearnMoreClick}>learn more?</a></p>
                    <Row className="text-md-start pb-5 pt-4">
                        <Col md className=""><input type="image" onClick={()=>window.open(links.applestore)} src="./applestore.svg" style={{height:44}}/></Col>
                        <Col md className=""><input type="image" onClick={()=>window.open(links.googlestore)} src="./googlestore.svg" style={{height:44}}/></Col>
                        <Col md className=""><input type="image" onClick={()=>window.open(links.signin,"_self")} src="./webapp.svg" style={{height:44}}/></Col>
                    </Row>
                </Col>
                <Col className="my-auto pb-5 pt-5" style={{background:"url(./brush.png)", backgroundRepeat:"no-repeat",  backgroundPosition: "center", backgroundSize: "cover"}}>
                    <img
                        alt=""
                        src="./apppreview.gif"
                        style={{height:"70vh"}}
                        className="border border-4 border-dark rounded"
                    />
                </Col>
            </Row>
        </Container>
    )
}
